import useList from '@/views/hooks/useList'
import store from '@/store'

export default function useMarquesList() {
  // DATA

  const list = useList({
    fetchAction: () => store.dispatch('modeles/initMarques'),
    deleteActionName: 'modeles/deleteMarque',
  })

  const tableColumns = [
    {
      label: 'Nom', key: 'nom', sortable: true,
    },
    { label: 'Actions', key: 'actions' },
  ]
  return {
    ...list,
    tableColumns,
  }
}
